// Single-product page template:

import React from "react"
import { Layout, ProductPage } from "../components"

const imageList = [
	{
		link:`/images/featured_medic2.jpg`,
		alt:`Two-tone black and grey flight suite with grey on top and black on botton in front of a white background.`,
	},
	{
		link:`/images/medic2-details.jpg`,
		alt:`A computer rendering of the two-tone flight suit used to demonstrate pocket layout.`,
	},
]


export default function ProductPageMedicII() {
	return (
		<Layout
			title="Medic II"
		>
			<ProductPage images={imageList}>

				<h2>Medic II Flight Suit</h2>
				<p>When you want a suit that will give you that unique look and set you apart from others, our two-tone Medic II is for you. This suit is designed like the Medic but in a two-color combination. Just tell us the colors that fit your program plus any other decoration that you need.</p>

				<h3>Features</h3>
				<ul>
					<li>2 angle chest pockets</li>
					<li>2” x 4” velcro for name tag</li>
					<li>2 thigh pockets, outside of leg</li>
					<li>1 Nurse (EMT) pocket on right leg</li>
					<li>2 lower leg pockets</li>
					<li>Zipper sleeve pocket</li>
					<li>Knife pocket</li>
					<li>Boot zipper entry</li>
				</ul>

			</ProductPage>
		</Layout>
	)
}
